@import './fonts.scss';

html,
body {
  height: 100%;

  #root {
    height: 100%;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#reportPDF {
  p, ol, ul {
    white-space: pre-wrap;
  }
}