/* 
.fc-day-sun {
  background:#FAFAFA;
}

.fc-day-sat {
  background: #FAFAFA;
}  */

.fc-timegrid-slot-label {
  border: 0 !important;
}
.fc-timegrid-slot-minor {
  border-width: 0.1px !important;
}

.fc-timegrid-slot {
  height: 36px !important;
}
.fc-day-today {
  background-color: transparent !important;
}

.fc-timegrid-col-events {
  margin: 0 !important;
}

.fc-timegrid-event {
  margin: 0 !important;
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.fc-event-main {
  padding: 0 !important;
}

.fc-header-toolbar {
  margin-bottom: 0 !important;
}

.fc-col-header-cell {
  text-align: left !important;
  text-transform: uppercase;
}

.fc-v-event {
  background-color: white;
  color: transparent;
}
.fc .fc-scrollgrid-section > td {
  border-left: 0;
  border-bottom: 0;
  border-top: 0;
}

.fc-scrollgrid {
  border: 0 !important;
}
.fc-day .fc-timegrid-col-frame {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid #ddd;
}
.fc-timegrid-axis {
  border: 0 !important;
}

.fc-scrollgrid-sync-inner {
  border-top: 1px solid #ddd;
}
