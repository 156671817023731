/* Fonts defined using the style linking method.
* (See https://www.fontspring.com/support/using-webfonts/style-linking) */

$fontFamily: "DIN Next LT W01 Regular";

/* Regular Weight */
@font-face {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/5563279/6b4d4830-9c7d-43d2-b5d3-c73f739561b9.woff2") format("woff2"), url("./assets/fonts/5563279/ccf25ada-6d2a-4133-95dc-deb039e22999.woff") format("woff");
}

/* Italic Style */
@font-face {
  font-family: $fontFamily;
  font-style: italic;
  font-weight: normal;
  src: url("./assets/fonts/692267/f37f8098-b16e-405f-ab24-bd595a5d5762.woff2") format("woff2"), url("./assets/fonts/692267/c5635d60-91fa-4e4b-8a51-41c97fc744c3.woff") format("woff");
}

/* Medium Weight */
@font-face {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/5563051/afabdfcf-432c-49b4-a496-0e5051e87fa9.woff2") format("woff2"), url("./assets/fonts/5563051/611ea865-db7e-4c05-94b8-bd1675c90576.woff") format("woff");
}

/* Bold Weight */
@font-face {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/692234/121784c1-863c-4e49-b682-625ea7de55b0.woff2") format("woff2"), url("./assets/fonts/692234/9949035f-6226-4080-a14a-bf7d94ffb1e1.woff") format("woff");
}

/* Bold Italic Style */
@font-face {
  font-family: $fontFamily;
  font-style: italic;
  font-weight: bold;
  src: url("./assets/fonts/692237/4e20348d-41a7-4295-94ab-2b1019e9d453.woff2") format("woff2"), url("./assets/fonts/692237/140a0a6f-f218-4071-8eb8-f2bc45ed5121.woff") format("woff");
}